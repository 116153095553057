@import "../../App.scss";

#redirect {
  &__msg {
    font-size: 14px;
    line-height: 1.71;
    font-style: italic;
    color: $primaryColor;
  }
}

#footer {
  &__container {
    height: 3.5em;
    display: flex;
    background-color: $footer;
    justify-content: space-between;
    align-items: center;
  }
  
  &__item_container_v1 {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 300px;
    margin: 0px 0px 5px 3px;

    span {
      font-size: 11.2px;
      color: $footerText;
      font-family: Inter;
      margin-left: 0.6em;
      margin: 0px 0px 0px 5px;
    }
  }

  &__item_container_v2 {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 340.56px;
    height: 16px;
    margin: 0px 0px 5px 3px;

    span {
      font-size: 8px;
      color: $footerText;
      font-family: Inter;
      line-height: 1em;
      margin: 0px 0px 0px 5px;
    }
  }
}

#animation {
  svg {
    height: 22em !important;
    width: 22em !important;
  }

  g > rect {
    opacity: 0;
  }
}

.dark_font {
  color: #fafafa !important;
}

#retry {
  &_button_v2 {
    width: 100%;
    height: 48px;
    margin: 0 0 18px 0;
    border: solid 1px $primaryColor;
    border-radius: 5px;
    background-color: $primaryColor;
    font-family: Inter,Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: white;
    cursor: pointer;
  }
}

#retry {
  &_button_v1 {
    width: 85%;
    height: 48px;
    margin: 0 0 18px 0;
    border: solid 1px $primaryColor;
    border-radius: 5px;
    background-color: $primaryColor;
    font-family: Inter,Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: white;
    cursor: pointer;
  }
}

.hv-divforcover {
  bottom: 1.5em;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: stretch;
  flex-direction: column;
  justify-content: center;
}
